<template>
  <div>
    <div class="pb-3">
      <slot name="body" />
    </div>
    <PanelButtons :show-divider="showDivider">
      <template #default>
        <slot name="actions" />
      </template>
    </PanelButtons>
  </div>
</template>

<script>
import { PanelButtons } from "../buttons";

export default {
  components: { PanelButtons },
  name: "BodyActions",
  props: {
    showDivider: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
