<template>
  <hr class="header-detail m-0">
</template>

<script>
export default {
  name: "HeaderDetail",
};
</script>

<style scoped>
.header-detail {
  width: var(--w-size-45);
  border-top: var(--w-border-size-20) solid var(--w-color-primary-on-light);
}
</style>
