<template>
  <LogoBase
    src="/public/logo-serpro.svg"
    v-bind="$attrs"
  />
</template>

<script>
import LogoBase from "./logo-base";

export default {
  components: { LogoBase },
  inheritAttrs: false,
  name: "LogoSerpro",
};
</script>
