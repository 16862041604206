<template>
  <OverlayBase :is-busy="pageIsBusy">
    <div class="page-wrapper">
      <PdfDocumentPageRender
        v-bind="$attrs"
        v-on="$listeners"
        @renderStarted="pageIsBusy = true"
        @renderFinished="pageIsBusy = false"
      />
    </div>
  </OverlayBase>
</template>
<script>
import { OverlayBase } from "../async";
import PdfDocumentPageRender from "./pdf-document-page-render";

export default {
  components: {
    OverlayBase,
    PdfDocumentPageRender,
  },
  data() {
    return {
      pageIsBusy: false,
    };
  },
  name: "PdfDocumentPage",
};
</script>

<style scoped>
.page-wrapper {
  overflow: scroll;
  max-height: var(--w-size-73);
  min-height: var(--w-size-73);
  --frame-color: var(--w-color-neutral-50);
  background-color: var(--frame-color);
  /*
    não foi possível centralizar o canvas da página com flex safe(1),
    position absolute(2) ou transform(2). quando há overflow de conteúdo
    causado pelo zoom, nos três casos, o scroll não é capaz de
    mostrar todo o canvas com a página.
    1: https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container)
    2: https://www.freecodecamp.org/news/how-to-center-anything-with-css-align-a-div-text-and-more/

    a única solução em que centralização e scroll funcionaram juntos, foi usar
    flex no pai e margin auto no elemento filho:
    https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
  */
  display: flex;
}

.page-wrapper canvas {
  margin: auto;
  border: var(--frame-color) solid var(--w-size-35);
}
</style>
