<template>
  <ControlWrapper
    :control-component="InputCelular"
    :rules="composedRules"
    :label="label"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import ControlWrapper from "./control-wrapper";
import InputCelular from "./input-celular";

export default {
  components: { ControlWrapper },
  computed: {
    composedRules() {
      const externalRules = this.rules ? `|${this.rules}` : "";
      return `celular${externalRules}`;
    },
  },
  data() {
    return {
      InputCelular,
    };
  },
  name: "ControlCelular",
  props: {
    label: {
      default: "Celular",
      type: String,
    },
    rules: {
      default: null,
      type: String,
    },
  },
};
</script>
