import { ROUTING_ACCESSES } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-archived-list-page.vue"
  );

export function createFlowsArchivedListRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.REQUESTER],
    },
    path: "/flows-archived/list",
    props: true,
  };
}
