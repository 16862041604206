export const sidebarLinksFull = [
  {
    dataTest: "nav-clients",
    group: "Administração",
    icon: "building",
    perfis: ["ADMINISTRADOR"],
    text: "Clientes",
    to: "/clients/list",
  },
  {
    dataTest: "nav-managers",
    group: "Administração",
    icon: "cog",
    perfis: ["ADMINISTRADOR", "GESTOR"],
    text: "Gestores",
    to: "/managers/list",
  },
  {
    dataTest: "nav-groups",
    group: "Administração",
    icon: "people",
    perfis: ["ADMINISTRADOR", "GESTOR"],
    text: "Grupos",
    to: "/groups/list",
  },
  {
    dataTest: "nav-requesters",
    group: "Administração",
    icon: "person",
    perfis: ["ADMINISTRADOR", "GESTOR"],
    text: "Requisitantes",
    to: "/requesters/list",
  },
  {
    dataTest: "nav-flows-management",
    group: "Assinatura",
    icon: "executive",
    perfis: ["REQUISITANTE"],
    text: "Fluxos",
    to: "/flows-management/list",
  },
  {
    dataTest: "nav-flows-archived",
    group: "Assinatura",
    icon: "archive",
    perfis: ["REQUISITANTE"],
    text: "Fluxos Arquivados",
    to: "/flows-archived/list",
  },
  {
    dataTest: "nav-flows-engagement",
    group: "Assinatura",
    icon: "executive",
    perfis: ["INTERESSADO"],
    text: "Fluxos",
    to: "/flows-engagement/list",
  },
  {
    dataTest: "nav-stakeholders",
    group: "Administração",
    icon: "person",
    perfis: ["ADMINISTRADOR", "GESTOR"],
    text: "Interessados",
    to: "/stakeholders/list",
  },
  {
    dataTest: "nav-stakeholders",
    group: "Assinatura",
    icon: "person",
    perfis: ["REQUISITANTE"],
    text: "Interessados",
    to: "/stakeholders/list",
  },
];
