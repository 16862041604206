<template>
  <DashboardPageBase
    :crumbs="crumbs"
    :store-modules="[storeModule]"
    :after-first-query="afterFirstQuerySaga"
  >
    <BodyActions show-divider>
      <template #body>
        <ApprovalsSummary :respostas="respostas" />
        <ApprovalsList :respostas="respostas" />
      </template>
      <template #actions>
        <slot />
      </template>
    </BodyActions>
  </DashboardPageBase>
</template>

<script>
import { extractRespostas } from "@/data";
import { BodyActions } from "@/lib";

import { DashboardPageBase } from "../dashboard-pages";
import ApprovalsList from "./approvals-list";
import ApprovalsSummary from "./approvals-summary";

export default {
  components: { ApprovalsList, ApprovalsSummary, BodyActions, DashboardPageBase },
  data() {
    return {
      afterFirstQuerySaga: {
        onError: this.loadError,
        onExecute: this.loadFluxo,
        onSuccess: this.loadSuccess,
      },
      respostas: [],
    };
  },
  methods: {
    loadError(e) {
      console.error(e);
      this.$notifyError(this.loadErrorMessage);
      this.$router.push(this.loadErrorRoute);
    },
    loadFluxo() {
      return this.$store.getters[`${this.storeModule}/getItemById`](this.itemId);
    },
    loadSuccess(fluxo) {
      this.respostas = extractRespostas(fluxo);
    },
  },
  name: "FlowApprovalsPageBase",
  props: {
    crumbs: {
      required: true,
      type: Array,
    },
    itemId: {
      required: true,
      type: String,
    },
    loadErrorMessage: {
      required: true,
      type: String,
    },
    loadErrorRoute: {
      required: true,
      type: String,
    },
    storeModule: {
      required: true,
      type: String,
    },
  },
};
</script>
