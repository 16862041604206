import { render, staticRenderFns } from "./link-dialog.vue?vue&type=template&id=3378ae39&scoped=true"
import script from "./link-dialog.vue?vue&type=script&lang=js"
export * from "./link-dialog.vue?vue&type=script&lang=js"
import style0 from "./link-dialog.vue?vue&type=style&index=0&id=3378ae39&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3378ae39",
  null
  
)

export default component.exports