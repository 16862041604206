import { ROUTING_ACCESSES } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-engagement-approval-page.vue"
  );

export function createFlowsEngagementApprovalRoutes() {
  return [
    {
      component,
      meta: {
        access: [ROUTING_ACCESSES.STAKEHOLDER],
      },
      path: "/flows-engagement/approval/:itemId",
      props: true,
    },

    // a quantidade de caracteres no link enviado pelo backend aos usuários é restringida pelos limites impostos a mensagens sms. o redirect abaixo permite que o backend adote uma url curta no sms e o frontend cuida de transforma ela aqui em algo conforme os padrões adotados nas demais urls.
    {
      path: "/r/:itemId",
      props: true,
      redirect: from => ({
        path: "/flows-engagement/approval/:itemId",
        query: {
          linkId: from.params.itemId,
          linkToken: from.query.t,
          perfil: ROUTING_ACCESSES.STAKEHOLDER,
          session: "clean",
        },
      }),
    },

  ];
}
