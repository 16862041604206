var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "hero-page" }, [
    _c("div", {
      staticClass: "hero-page-background",
      style: { backgroundImage: `url(${_setup.heroImage})` },
    }),
    _vm._v(" "),
    _c("main", { staticClass: "hero-page-content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }