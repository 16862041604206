import { ROUTING_ACCESSES } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-engagement-acknowledged-page.vue"
  );

export function createFlowsEngagementAcknowledgedRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.STAKEHOLDER],
    },
    path: "/flows-engagement/acknowledgment/:fluxoId/:documentoId",
    props: true,
  };
}
