<template>
  <FragmentBase>
    <LogoBase
      :src="src.full"
      height="40px"
      width="200px"
      class="logo-full"
      :style="cssVars"
      v-bind="$attrs"
    />
    <LogoBase
      :src="src.min"
      height="40px"
      class="logo-min"
      :style="cssVars"
      v-bind="$attrs"
    />
  </FragmentBase>
</template>

<script>
import { FragmentBase } from "../layout";
import LogoBase from "./logo-base";

export default {
  components: { FragmentBase, LogoBase },
  computed: {
    cssVars() {
      return {
        "--full-xs": this.responsive ? "none" : "flex",
        "--min-xs": this.responsive ? "flex" : "none",
      };
    },
  },
  data() {
    return {
      src: {
        full: "/public/logo-neosigner-512x102.png",
        min: "/public/icone-neosigner.svg",
      },
    };
  },
  inheritAttrs: false,
  name: "LogoApp",
  props: {
    responsive: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.logo-full {
  display: var(--full-xs) !important;
}

.logo-min {
  display: var(--min-xs) !important;
}

@media (min-width: 576px) {
  .logo-full {
    display: flex !important;
  }
  .logo-min {
    display: none !important;
  }
}
</style>
