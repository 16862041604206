<template>
  <p
    v-if="text"
    class="subheader text-secondary"
  >
    {{ text }}
  </p>
</template>

<script>
export default {
  name: "SubheaderText",
  props: {
    text: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: var(--w-font-size-35);
  font-weight: var(--w-font-weight-20);
}
</style>
