<template>
  <span
    class="shifter"
    :style="cssVars"
  ><slot /></span>
</template>

<script>
export default {
  computed: {
    cssVars() {
      return {
        "--desktop": this.desktop,
        "--mobile": this.mobile,
      };
    },
  },
  name: "ColorShifter",
  props: {
    desktop: {
      default: "revert",
      type: String,
    },
    mobile: {
      default: "revert",
      type: String,
    },
  },
};
</script>

<style scoped>
.shifter {
  color: var(--mobile);
}

@media (min-width: 576px) {
  .shifter {
    color: var(--desktop);
  }
}
</style>
