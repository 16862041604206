var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DashboardPage", {
    attrs: {
      crumbs: _vm.crumbs,
      "side-links": _vm.sidebarLinks,
      store: _vm.$store,
      "after-first-query": _vm.afterFirstQuery,
      "header-text": _vm.headerTextNormalized,
      "no-card": _vm.noCard,
      "show-linear": _vm.showLinear,
      "show-overlay": _vm.showOverlay,
      "store-modules": _vm.storeModules,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "navStart",
          fn: function () {
            return [_c("LogoApp", { attrs: { responsive: "" } })]
          },
          proxy: true,
        },
        {
          key: "navEnd",
          fn: function () {
            return [_c("UserMenu")]
          },
          proxy: true,
        },
        {
          key: "content",
          fn: function (slotProps) {
            return [_vm._t("default", null, null, slotProps)]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }