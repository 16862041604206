<template>
  <div>
    <AppIcon
      name="info"
      variant="info"
      class="d-inline"
    />
    <span class="info-text"><slot /></span>
  </div>
</template>

<script>
import { AppIcon } from "../icons";

export default {
  components: { AppIcon },
  name: "InfoText",
};
</script>

<style>
.info-text {
  color: var(--w-color-neutral-40);
}
</style>
