<template>
  <DashboardPage
    :crumbs="crumbs"
    :side-links="sidebarLinks"
    :store="$store"
    :after-first-query="afterFirstQuery"
    :header-text="headerTextNormalized"
    :no-card="noCard"
    :show-linear="showLinear"
    :show-overlay="showOverlay"
    :store-modules="storeModules"
  >
    <template #navStart>
      <LogoApp responsive />
    </template>
    <template #navEnd>
      <UserMenu />
    </template>

    <template #content="slotProps">
      <slot v-bind="slotProps" />
    </template>
  </DashboardPage>
</template>
<script>
import { DashboardPage, LogoApp } from "@/lib";
import { mapGetters } from "vuex";

import { sidebarLinksFull } from "./side-bar-links";
import UserMenu from "./user-menu";

export default {
  components: { DashboardPage, LogoApp, UserMenu },
  computed: {
    ...mapGetters("auth", ["perfilIsAnyOf"]),
    headerTextNormalized() {
      if (this.headerText) return this.headerText;
      return this.crumbs.at(-1).text;
    },
    sidebarLinks() {
      return sidebarLinksFull.filter(link => this.perfilIsAnyOf(link.perfis));
    },
  },
  name: "DashboardPageBase",
  props: {
    afterFirstQuery: {
      default: null,
      type: Object,
    },
    crumbs: {
      required: true,
      type: Array,
    },
    headerText: {
      default: null,
      type: String,
    },
    noCard: {
      default: false,
      type: Boolean,
    },
    showLinear: {
      default: false,
      type: Boolean,
    },
    showOverlay: {
      default: false,
      type: Boolean,
    },
    storeModules: {
      default: null,
      type: Array,
    },
  },
};
</script>
