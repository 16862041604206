import { ROUTING_ACCESSES } from "@/views/widgets";

import component from "./not-found-page.vue";

export function createNotFoundRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.PUBLIC],
    },
    path: "/404",
  };
}
