<template>
  <div class="d-flex">
    <BImg
      :src="src"
      :height="height"
      :width="width"
      alt="logo"
    />
  </div>
</template>

<script>
import { BImg } from "../bv";

export default {
  components: { BImg },
  name: "LogoBase",
  props: {
    height: {
      default: "40px",
      type: [Number, String],
    },
    src: {
      required: true,
      type: String,
    },
    width: {
      default: null,
      type: [Number, String],
    },
  },
};
</script>
