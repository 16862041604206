var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OverlayBase",
    { attrs: { "is-busy": _vm.isBusy } },
    [
      _c("DialogForm", {
        ref: "form",
        attrs: { "external-errors": _vm.lastError },
        on: {
          save: function ($event) {
            return _vm.execute(_vm.authenticate)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _vm.lastError
                  ? _c(
                      "div",
                      { staticClass: "centered" },
                      [
                        _c("DescriptionText", [
                          _vm._v(
                            "\n          Algo deu errado com sua autenticação via link mas não se preocupe. O Neosigner tem outras formas de acesso.\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.lastError.message
                          ? _c("AlertText", {
                              attrs: {
                                variant: "warning",
                                icon: "alert",
                                text: _vm.lastError.message,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("ButtonPrimary", {
                          attrs: {
                            label: "Tentar outra forma de autenticação",
                            field: "back",
                          },
                          on: { click: _vm.cancel },
                        }),
                      ],
                      1
                    )
                  : _c("DescriptionText", { staticClass: "centered" }, [
                      _vm._v(
                        "\n        Por favor, aguarde enquanto tentamos te autenticar.\n      "
                      ),
                    ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }