import { ROUTING_ACCESSES } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./clients-list-page.vue"
  );

export function createClientsListRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR],
    },
    path: "/clients/list",
  };
}
