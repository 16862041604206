import { listSignedInRoutingAccesses } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./logs-view-page.vue"
  );

export function createLogsViewRoute() {
  return {
    component,
    meta: {
      access: listSignedInRoutingAccesses(),
    },
    path: "/logs/view/:itemId",
    props: true,
  };
}
