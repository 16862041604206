<template>
  <div class="container">
    <div class="code-input-box">
      <input
        v-for="input in inputs"
        :id="input.id"
        :key="input.id"
        :input-order="input.order"
        type="text"
        placeholder="*"
        class="char-input"
        maxlength="1"
        autocomplete="off"
        inputmode="numeric"
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const inputs = [];
    for (let i = 1; i <= this.length; i++) {
      inputs.push({ id: this.field + "_" + i, order: i, value: "" });
    }
    return {
      focusObj: 0,
      inputs: inputs,
      texto: "",
    };
  },
  methods: {
    changeValue(event) {
      if (event.keyCode === 8 || event.keyCode === 46) {
        // Backspace e Delete
        if (this.texto.length > 0) {
          this.setText(this.texto.substr(0, this.texto.length - 1));
        }
      }
      else {
        // Outras teclas
        const inputId = event.currentTarget.id;
        const value = event.currentTarget.value;

        const input = this.inputs.find(i => i.id === inputId);
        this.setValue(input, value);
        if (input.order < this.length && input.value) {
          const next = this.inputs.find(i => i.order === input.order + 1);
          document.getElementById(next.id).focus();
        }
        if (input.order > 1 && !input.value) {
          const prior = this.inputs.find(i => i.order === input.order - 1);
          document.getElementById(prior.id).focus();
        }
        this.$emit("input", this.texto);
      }
    },
    handlePaste(event) {
      // Stop data actually being pasted into div
      event.stopPropagation();
      event.preventDefault();

      // Get pasted data via clipboard API
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("Text");

      this.setText(pastedData);
    },
    setFocus(event) {
      let order = 0;
      if (event) {
        const inputId = event.currentTarget.id;
        const input = this.inputs.find(i => i.id === inputId);
        order = input.order;
      }
      if (order !== this.focusObj) {
        let isSetted = false;
        const thisComponent = this;
        this.inputs.forEach((input) => {
          if (!isSetted && input.value === "") {
            isSetted = true;
            thisComponent.focusObj = input.order;
            document.getElementById(input.id).focus();
            return true;
          }
        });
        if (!isSetted) {
          isSetted = true;
          const lastInput = this.inputs[this.inputs.length - 1];
          this.focusObj = lastInput.order;
          document.getElementById(lastInput.id).focus();
        }
      }
    },
    setText(text) {
      this.inputs.forEach((input) => {
        if (input.order <= text.length) {
          input.value = text.charAt(input.order - 1);
        }
        else {
          input.value = "";
        }
        this.setValue(input, input.value);
      });
      this.setFocus();
      this.$emit("input", this.texto);
    },
    setValue(input, newValue) {
      if (newValue === " ") newValue = "";
      newValue = newValue.toUpperCase();
      input.value = newValue;
      document.getElementById(input.id).value = newValue;
      this.texto = this.inputs.map(input => input.value).join("");
    },
  },
  mounted() {
    this.inputs.forEach((input) => {
      const inputElement = document.getElementById(input.id);
      inputElement.addEventListener("keyup", this.changeValue);
      inputElement.addEventListener("paste", this.handlePaste);
      inputElement.addEventListener("focus", this.setFocus);
    });
    this.setText(this.value);

    if (this.autofocus) {
      document.getElementById(this.inputs[0].id).focus();
    }
  },
  name: "ControlCodeInput",
  props: {
    autofocus: {
      default: false,
      type: Boolean,
    },
    field: {
      default: "code",
      type: String,
    },
    length: {
      default: 6,
      type: Number,
    },
    value: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped>
.container {
  max-width: var(--w-size-70);
}
.code-input-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.char-input {
  /*
    esse tamanho de fonte é especialmente importante no ios.
    foi verificado (em um iPhone 6s, iOS 14.4, Safari 14) que
    fontes maiores fazem o texto ser ocultado pelo input.
  */
  font-size: var(--w-font-size-30);
  /* harmoniza a altura do input ao tamanho reduzido da fonte */
  line-height: var(--w-size-40);
  width: var(--w-size-35);
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: 4px;
  border-color: var(--w-color-primary-on-light);
  background-color: var(--w-color-neutral-10);
  text-align: center;
}

@media (min-width: 576px) {
  .char-input {
    font-size: var(--w-font-size-45);
    line-height: revert;
  }
}

.char-input:focus-visible {
  outline: none;
}
</style>
