import { ROUTING_ACCESSES } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./stakeholders-list-page.vue"
  );

export function createStakeholdersListRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR, ROUTING_ACCESSES.MANAGER, ROUTING_ACCESSES.REQUESTER],
    },
    path: "/stakeholders/list",
  };
}
