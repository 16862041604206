<template>
  <p
    class="display-text text-center"
    :style="cssVars"
  >
    <slot />
  </p>
</template>

<script>
export default {
  computed: {
    cssVars() {
      return {
        "--font-size": this.fontSizeVar,
      };
    },
    fontSizeVar() {
      if (this.size === 5) return "var(--w-font-size-45)";
      if (this.size === 4) return "var(--w-font-size-50)";
      if (this.size === 3) return "var(--w-font-size-60)";
      if (this.size === 2) return "var(--w-font-size-70)";

      return "var(--w-font-size-85)";
    },
  },
  name: "DisplayText",
  props: {
    size: {
      default: 1,
      type: Number,
    },
  },
};
</script>

<style>
.display-text {
  text-shadow: var(--w-shadow-primary);
  font-size: var(--font-size);
  margin: 0;
  padding: 0;
  line-height: 1.2;
}
</style>
