<template>
  <BButtonToolbar justify>
    <slot />
  </BButtonToolbar>
</template>

<script>
import { BButtonToolbar } from "../bv";

export default {
  components: { BButtonToolbar },
  name: "ToolbarButtons",
};
</script>
