import { ROUTING_ACCESSES } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./requesters-webhook-page.vue"
  );

export function createRequestersWebhookRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR, ROUTING_ACCESSES.MANAGER],
    },
    path: "/requesters/webhook/:itemId",
    props: true,
  };
}
