var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DashboardPageBase",
    {
      attrs: {
        crumbs: _vm.crumbs,
        "store-modules": [_vm.storeModule],
        "after-first-query": _vm.afterFirstQuerySaga,
      },
    },
    [
      _c("BodyActions", {
        attrs: { "show-divider": "" },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function () {
                return [
                  _c("ApprovalsSummary", {
                    attrs: { respostas: _vm.respostas },
                  }),
                  _vm._v(" "),
                  _c("ApprovalsList", { attrs: { respostas: _vm.respostas } }),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [_vm._t("default")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }