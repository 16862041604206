import { ROUTING_ACCESSES } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-archived-view-page.vue"
  );

export function createFlowsArchivedViewRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.REQUESTER],
    },
    path: "/flows-archived/view/:itemId",
    props: true,
  };
}
