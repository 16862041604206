<template>
  <button
    class="close-button"
    @click="$emit('click')"
  >
    <AppIcon name="close" />
  </button>
</template>

<script>
import { AppIcon } from "../icons";

export default {
  components: { AppIcon },
  name: "ButtonClose",
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0;
  padding: var(--w-size-20);
}

.close-button::v-deep svg {
  fill: var(--w-color-neutral-50);
}
.close-button:hover::v-deep svg {
  fill: var(--w-color-neutral-70);
}
</style>
