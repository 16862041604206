var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.HeroPage,
    [
      _c(
        _setup.CenterBase,
        [
          _c(
            _setup.ColorShifter,
            { attrs: { desktop: "var(--w-color-neutral-10)" } },
            [
              _c(_setup.DisplayText, { attrs: { size: 1 } }, [
                _vm._v("\n        404\n      "),
              ]),
              _vm._v(" "),
              _c(_setup.DisplayText, { attrs: { size: 5 } }, [
                _vm._v(
                  "\n        A página que você tentou acessar não foi encontrada.\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(_setup.SpacerBase, { attrs: { vertical: "20", block: "" } }),
          _vm._v(" "),
          _c(_setup.ButtonSecondary, {
            staticClass: "mt-3 w-auto",
            attrs: { label: "Ir para o início" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/default")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }