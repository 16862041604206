<template>
  <NavUser
    :title="nome"
    field="user"
    :badge="badge"
    :sub-title="`${perfil} | ${nomeCliente}`"
  >
    <template #menu>
      <div>
        <template v-if="hasMultiplePerfis">
          <UserMenuProfile />
          <MenuDivider />
        </template>
        <template v-if="isChangelogAvailable">
          <UserMenuChangelog />
          <MenuDivider />
        </template>
        <UserMenuHelp />
        <MenuDivider />
        <UserMenuSignOut />
      </div>
    </template>
  </NavUser>
</template>

<script>
import { MenuDivider, NavUser } from "@/lib";
import { mapGetters } from "vuex";

import UserMenuChangelog from "./user-menu-changelog.vue";
import UserMenuHelp from "./user-menu-help.vue";
import UserMenuProfile from "./user-menu-profile.vue";
import UserMenuSignOut from "./user-menu-sign-out.vue";

export default {
  components: { MenuDivider, NavUser, UserMenuChangelog, UserMenuHelp, UserMenuProfile, UserMenuSignOut },
  computed: {
    ...mapGetters("auth", ["nome", "nomeCliente", "perfil", "perfis", "perfilIsAnyOf"]),
    ...mapGetters("changelog", ["prettyHowManyUnread", "howManyLogs"]),
    badge() {
      if (this.howManyLogs <= 0) return null;
      return this.prettyHowManyUnread;
    },
    hasMultiplePerfis() {
      return this.perfis.length > 1;
    },
    isChangelogAvailable() {
      return this.howManyLogs > 0;
    },
  },
  name: "UserMenu",
};
</script>
