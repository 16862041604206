<template>
  <span>
    <slot
      :is-busy="isBusy"
      :execute="execute"
      :last-error="lastError"
    />
  </span>
</template>

<script>
import { useExecute } from "./use-execute";

export default {
  name: "AsyncExecute",
  setup() {
    const { execute, isBusy, lastError } = useExecute();
    return {
      execute,
      isBusy,
      lastError,
    };
  },
};
</script>
