<template>
  <FragmentBase>
    <ButtonSecondary
      :field="backField"
      :label="backLabel"
      @click="$emit('back')"
    />
    <ButtonPrimary
      :field="saveField"
      :label="saveLabel"
      @click="$emit('save')"
    />
  </FragmentBase>
</template>

<script>
import { FragmentBase } from "../layout";
import ButtonPrimary from "./button-primary";
import ButtonSecondary from "./button-secondary";

export default {
  components: { ButtonPrimary, ButtonSecondary, FragmentBase },
  name: "ButtonsSaveBack",
  props: {
    backField: {
      default: "back",
      type: String,
    },
    backLabel: {
      default: "Voltar",
      type: String,
    },
    saveField: {
      default: "save",
      type: String,
    },
    saveLabel: {
      default: "Salvar",
      type: String,
    },
  },
};
</script>
